import React from "react";
import styled from "styled-components";
import { Tools } from "./mods/tools/Tools";
import { StyleBase } from "./ux/StyleBase";

const Header = styled.div`
  height: 80px;
  width: 100%;
`;

/**
 * 核心样式
 */
const Paper = styled.div`
  border: 2px solid #cccccc;
  border-radius: 5px;
  box-shadow: 1px 1px 7px 1px #ccc;
  width: ${StyleBase.paperWidth}px;
  height: ${StyleBase.paperHeight}px;
  padding: 16px;
  margin: 0 auto;
`;

function App() {
  return (
    <>
      <Header />
      <Paper className='app'>
        <Tools />
      </Paper>
    </>
  );
}

export default App;
