import React, { FC } from "react";
import styled from 'styled-components';
import loadable from "@loadable/component";
import { Link, Switch, Route, useLocation } from "react-router-dom";

import { StyleBase } from "../../ux/StyleBase";

const ToolContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
`;

const SideBar = styled.div`
    width: ${StyleBase.sidebarWidth}px;
    padding: 10px 0;
    display: block;
`;

const Divider = styled.div`
    min-height: 100%;
    width: 2px;
    background-color: #DDDDDD;
    margin: 0 10px;
`;

const ToolContent = styled.div`
    flex-grow: 1;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

/**
 * a 默认不是 block element，增加一个容器以方便设置间隔样式
 */
const EntryContainer = styled.div`
    margin-bottom: 7px;
`;

/**
 * 给 react-router 的组件设置样式
 * box-shadow 不会影响盒子模型（改变尺寸）
 */
const Entry = styled(Link)<{selected?: boolean}>`
    text-decoration: none;
    box-shadow: ${props => props.selected ? '0 3px #ccc' : 'none'};
`;

/**
 * 功能列表配置，需要名字，路径（路由），异步加载的组件
 */
const Entries = [
    {
        name: 'QRCode',
        path: '/qrcode',
        Comp: loadable(() => import('./QRCode'))
    },
    {
        name: 'UUID',
        path: '/UUID',
        Comp: loadable(() => import('./UUID'))
    },
];

export const Tools: FC = () => {
    const location = useLocation();

    return (
        <>
            <ToolContainer>
                <SideBar>
                    {Entries.map((entry, i) => (
                        <EntryContainer key={i}>
                            <Entry selected={location.pathname === entry.path} to={entry.path} >{entry.name}</Entry>
                        </EntryContainer>
                    ))}
                </SideBar>
                <Divider />
                <ToolContent>
                <Switch>
                    {Entries.map((entry, i) => <Route key={i} path={entry.path} component={entry.Comp} />)}
                </Switch>
                </ToolContent>
            </ToolContainer>
        </>
    );
}